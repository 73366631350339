import React from 'react'
import { Link } from 'react-router-dom';

import alscarboroughLogo from '../imgs/color-logo.png';
import aliciascarboroughLogo from '../imgs/alicia-scarborough.png';

const LP = () => {
  return (
    <main className="main-lp trajan-regular">
        <video loop={true} autoPlay="autoplay" muted defaultmuted="true" playsInline id="mist-bkg">
            <source src="mist-bkg.mp4" type="video/mp4" />
        </video>
        <header>
            <h1 className="trajan-regular">Alicia Scarborough</h1>
            <h5 className="trajan-regular">I am an author and writing is my daily coffee.</h5>
        </header>
        <div className="col-sm-12">
            <div className="col-sm-3 col-xs-12">
                <h5 className="trajan-regular">Adult Readers</h5>
                <Link to="/al-scarborough">
                    <picture>
                        <img src={alscarboroughLogo} alt="A.L. Scarborough - Urban Fantasy and Paranormal Thrillers" className="logo-1" />
                    </picture>
                </Link>
                <Link className="btn white" to="/al-scarborough">Enter</Link>
            </div>
            <hr className="mobile" />
            <div className="col-sm-3 col-xs-12">
                <h5 className="trajan-regular">Youth Readers</h5>
                <Link to="/alicia-scarborough">
                    <picture>
                        <img src={aliciascarboroughLogo} alt="Alicia Scarborough" className="logo-2" />
                    </picture>
                </Link>
                <Link className="btn white" to="/alicia-scarborough">Enter</Link>
            </div>
        </div>
    </main>
  )
}

export default LP