import React from 'react';

const ALSSocialLinks = () => {
  return (
    <>
        <a href="https://www.amazon.com/stores/author/B07CLFS9N8" className="amazon" rel="noreferrer" target="_blank"><span className="sr-only">Amazon</span></a>
        <a href="https://www.facebook.com/aliciascarboroughauthor/" className="facebook" rel="noreferrer" target="_blank"><span className="sr-only">facebook</span></a>
        <a href="https://www.tiktok.com/@foxglove1028" className="tiktok" rel="noreferrer" target="_blank"><span className="sr-only">twitter</span></a>
    </>
  )
}

export default ALSSocialLinks