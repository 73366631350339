import { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";

import useData from '../../hooks/useData';

const imgBaseURL = (process.env.NODE_ENV !== 'production') ? 'https://www.aliciascarborough.com/' : '';

const BookName = () => {

  const { dataALS } = useData();

  const params = useParams();
  const navigate = useNavigate();

  const [seriesLoaded,setSeriesLoaded] = useState(false);
  const [bookSeriesData,setBookSeriesData] = useState();
  const [bookData,setBookData] = useState();
  const [book,setBook] = useState();
  const [buyLinks,setBuyLinks] = useState();

  const [imgUrl,setImgUrl] = useState();
  const [bookTitle,setBookTitle] = useState();
  const [bookDesc,setBookDesc] = useState();
  const [bookNo,setBookNo] = useState();

  const updateBook = useCallback(() => {    
    if(book) {
      setImgUrl(imgBaseURL+book.picture);
      setBookTitle(book.bookTitle);
      setBookDesc(book.bookDesc);
      setBookNo(book.bookNo);
    }
  },[book]);

  const updateBuyLinks = useCallback(() => {
    if(book?.links){
      setBuyLinks(book?.links?.map((lnk) => {
        return (
            <a href={lnk.url} target="_blank" rel="noreferrer" key={lnk.url+lnk.name} className="flex col-1 center trajan-regular">
              {lnk.name}
            </a>
        );
      }));
    }
  },[book?.links]);

  const updateBookData = useCallback(() => {
    if(bookSeriesData) {
      if(bookData) {
        updateBook();
        if(book) {
          updateBuyLinks();
        }
      }
    }
  },[book,bookData,bookSeriesData,updateBook,updateBuyLinks]);

  useEffect(()=>{
    if(dataALS) {
      if(!seriesLoaded) {
        setBookSeriesData(dataALS?.series?.filter((s => s.series_name === params.SeriesName)));
        if(bookSeriesData) {
          setBookData(bookSeriesData[0]?.books?.filter((bk=>bk.bookTitle === params.BookName)));
          if(bookData) {
            setBook(bookData[0]);
            if(book){
              updateBookData();
              setSeriesLoaded(true);
            }
          }
        }
      }
    }
  },[book, bookData, bookSeriesData, dataALS, params.BookName, params.SeriesName, seriesLoaded, updateBookData]);
  
  return (
    <section className="content col-sm-12 col-xs-12">
      <div className="col-sm-8 col-xs-10">
        <div className="breadcrumbs col-sm-12 col-xs-12">
          <Link to={'..'} onClick={(e)=>{e.preventDefault();navigate(-1);}} className="trajan-regular"><span>Back</span></Link>
        </div>
        <header className="center mobile">
          {bookTitle}
          <br />
          <small style={{fontSize:'.9rem'}}>Book #{bookNo}</small>
        </header>
        <div className="col-sm-12 col-xs-12 book">
          <div className="wrapper flex spacebetween">
            <div className="col-sm-4">
              <picture className="bookCover">
                <img src={imgUrl} alt="" />
              </picture>
            </div>
            <div className="col-sm-7">
              <h5 className="desktop">
                {bookTitle}
                <br />
                <small style={{fontSize:'.9rem'}}>Book #{bookNo}</small>
              </h5>
              <p dangerouslySetInnerHTML={{__html:bookDesc}}></p>
              {(buyLinks) ? 
              <>
                <h5 style={{borderBottom: '0',margin: '40px auto 0'}}>Grab your copy from:</h5>
                <nav className="buyLinks spacebetween" style={{borderTop: '0',margin:'0',paddingTop:0}}>
                  {buyLinks}
                </nav>
              </>
              : null }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BookName