import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import useData from '../../hooks/useData';

import Swiper from 'swiper';

const ALSSeries = () => {

  const { dataALS } = useData();

  const [series, setSeries] = useState();
  const [swiperInit, setSwiperInit] = useState(false);
  const [windowWidth, setWindowWidth] = useState();
  const [swiperEl,setSwiperEl] = useState();

  const imgBaseURL = (process.env.NODE_ENV !== 'production') ? 'https://www.aliciascarborough.com' : '';
  
  const updateSeries = useCallback(() => {
      setSeries(dataALS?.series?.map((series) => {
      return(
        <Link to={"/al-scarborough/series/"+series.series_name} key={series.series_abbr+'_'+series.id} className="flex col center trajan-regular swiper-slide">
          <picture><img className="circle" src={imgBaseURL+series.img} alt="" /></picture>
          <span>{series.series_name}</span>
          <span style={{color: '#ffe974', fontSize: '1.2rem', lineHeight: '1.1rem', marginTop: '.5rem'}}>Learn More</span>
        </Link>
      );
    }));
  },[dataALS.series, imgBaseURL]);

  useEffect(()=>{
    if(dataALS) {
      updateSeries();
    }
  },[dataALS,updateSeries]);

  useEffect(()=>{
    if(swiperInit && windowWidth > 991) {
      setSwiperInit(false);
      if(swiperEl !== undefined) {
        console.log('destroy');
        swiperEl.destroy(true, true);
        setSwiperEl(undefined);
      }
    } else if (!swiperInit && windowWidth < 992) {
      setSwiperInit(true);
      if(swiperEl === undefined) {
        console.log('create');
        setSwiperEl(new Swiper('.swiper', {centeredSlides: true, speed: 400, spaceBetween: 100, slidesPerView: 1.5, pagination: {el: '.swiper-pagination', dynamicBullets: true}}));
      } else {
        console.log('init');
        swiperEl.init();
      }
    }
  },[windowWidth,swiperEl,swiperInit]);

  const updateSize = (e) => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(()=>{    
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize',updateSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <section className="content col-sm-12 col-xs-12">
      <div className="col-sm-8 col-xs-10">
        <header className="center">Series</header>
        <div className="col-sm-12 col-xs-12 series">
          <div className="col-sm-12 col-xs-12 swiper">
            <div className="wrapper grid-4 swiper-wrapper">
              {series}
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </section>
    
  )
}

export default ALSSeries