import { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from "react-router-dom";

import useData from '../../hooks/useData';

const imgBaseURL = (process.env.NODE_ENV !== 'production') ? 'https://www.aliciascarborough.com' : '';
const baseSeriesURL = "/al-scarborough/series/";
const baseCharURL = "/al-scarborough/characters/";

const CharacterName = () => {

  const { dataALS } = useData();

  const params = useParams();

  const [updated,setIsUpdated] = useState(false);
  const [seriesName, setSeriesName] = useState();
  const [characterSeriesData,setCharacterSeriesData] = useState();
  const [characterData,setCharacterData] = useState();
  const [character,setCharacter] = useState();

  const [imgUrl,setImgUrl] = useState();
  const [characterName,setCharacterName] = useState();
  const [characterDesc,setCharacterDesc] = useState();
  const [gallery,setGallery] = useState([]);
  const [gallerySlides,setGallerySlides] = useState([]);
  const [gallerySlideThumbs,setGallerySlideThumbs] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);

  const updateSlides = () => {
    let i,
        slides = document.querySelectorAll('.mySlides');
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }
    slides[slideIndex].style.display = 'block';
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const openModal = (i) => {
    document.getElementById("gallery_modal").style.display = "block";
    setSlideIndex(i);
    updateSlides();
  }

  const closeModal = () => {
    document.getElementById("gallery_modal").style.display = "none";
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentSlide = (i) => {
    setSlideIndex(i);
    updateSlides();
  }

  const prevSlide = () => {
    const slideNum = (slideIndex - 1 > -1) ? slideIndex - 1 : 0;
    setSlideIndex(slideNum);
    updateSlides();
  }

  const nextSlide = () => {
    const slideNum = (slideIndex + 1 < gallerySlides.length) ? slideIndex + 1 : gallerySlides.length - 1;
    setSlideIndex(slideNum);
    updateSlides();
  }

  const updateCharacter = useCallback(() => {
    if(character) {
      setImgUrl(imgBaseURL+character?.picture);
      setCharacterName(character?.name);
      setCharacterDesc(character?.char_desc);
      console.log(character);
      /*setGallery(character?.map((gi,idx)=>{
        const u = baseCharURL+seriesName+"/"+character.name+"/"+gi;
        return (
          <span onclick={openModal(idx)} className="hover-shadow cursor">
            <img src={u} alt={gi.desc} />
          </span>
        )
      }));
      setGallerySlides(character?.map((gi)=>{
        const u = baseCharURL+seriesName+"/"+character.name+"/"+gi;
        return (
          <div className="mySlides">
            <img src={u} alt={gi.desc} />
          </div>
        )
      }));
      setGallerySlideThumbs(character?.map((gi,idx)=>{
        const u = baseCharURL+seriesName+"/"+character.name+"/"+gi;
        return (
          <span className="thumb" onclick={currentSlide(idx)}>
            <img src={u} alt={gi.desc} />
          </span>
        )
      }));*/
    }
  },[character, currentSlide, openModal, seriesName]);

  const updateCharacterData = useCallback(() => {
    updateCharacter();
    setIsUpdated(true);
  },[updateCharacter]);

  useEffect(()=>{
    if(dataALS && !updated) {
      setCharacterSeriesData(dataALS?.series?.filter((s => s?.series_name === params.SeriesName)));
      if(characterSeriesData) {
        setSeriesName(params.SeriesName);
        setCharacterData(characterSeriesData[0]?.characters?.filter((char=>char?.name === params.Name)));
        if(characterData) {
          setCharacter(characterData[0]);
          if(character) {
            updateCharacterData();
          }
        }
      }
    }
  },[character, characterData, characterSeriesData, dataALS, params, updateCharacterData, updated]);
  
  return (
    <section className="content col-sm-12 col-xs-12">      
      <div className="col-sm-8 col-xs-10">
        <div className="breadcrumbs col-sm-12 col-xs-12">
          <Link to={baseSeriesURL+seriesName} className="img"><span>Back to Series</span></Link>
        </div>
        <header className="center mobile">
          {characterName}
        </header>
        <div className="col-sm-12 col-xs-12 character">
          <div className="wrapper flex spacebetween">
            <div className="col-sm-4">
              <picture className="characterProfile">
                <img src={imgUrl} alt="" />
              </picture>
            </div>
            <div className="col-sm-7">
              <h5 className="desktop">
                {characterName}
              </h5>
              <p dangerouslySetInnerHTML={{__html:characterDesc}}></p>
            </div>
          </div>
          {(gallery && gallery.length > 0) ? 
          <><div className="col-sm-12 col-xs-12 character">
            <div className="wrapper flex spacebetween">
              {gallery}
            </div>
          </div>
          {(gallerySlides && gallerySlides.length > 0) ? 
          <div id="gallery_modal" class="modal">
            <span className="close cursor" onclick={closeModal}>&times;</span>
            <div className="modal-content">
              {gallerySlides}
              <div className="controls">
                <button className="prev" onclick={prevSlide}>&#10094;</button>
                <button className="next" onclick={nextSlide}>&#10095;</button>
              </div>
              <div className="thumbNav">
                {gallerySlideThumbs}
              </div>
            </div>
          </div>
          : null }
          </>
          : null }
        </div>
      </div>
    </section>
  )
}

export default CharacterName