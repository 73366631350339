import React from 'react'
import logo from '../imgs/color-logo.png';
import mobile_logo from '../imgs/arcane circle@2x.png';
import { NavLink,Link } from 'react-router-dom';
import ALSSocialLinks from './ALSSocialLinks';

const ALSHeader = () => {
  return (
    <header className="header">
        <picture className="col-sm-2 col-xs-1">
            <Link to="/al-scarborough" className="img desktop"><img src={logo} className="logo" alt="A.L. Scarborough - Urban Fantasy and Paranormal Thrillers" /></Link>
            <Link to="/al-scarborough" className="img mobile"><img src={mobile_logo} className="logo" alt="A.L. Scarborough - Urban Fantasy and Paranormal Thrillers" /></Link>
        </picture>
        <nav className="main col-sm-4 col-xs-6">
            <NavLink to="books" className={({ isActive }) => "nav-link" + (isActive ? " current" : "")}>Books</NavLink>
            <NavLink to="series" className={({ isActive }) => "nav-link" + (isActive ? " current" : "")}>Series</NavLink>
            <NavLink to="about" className={({ isActive }) => "nav-link" + (isActive ? " current" : "")}>About</NavLink>
            <NavLink to="newsletter" className={({ isActive }) => "nav-link" + (isActive ? " current" : "")}>Newsletter</NavLink>
        </nav>
        <nav className="social col-sm-2 desktop">
           <ALSSocialLinks />
        </nav>
        <nav className="siteSwitch">
            <Link to="/al-scarborough" className="current">Adults</Link>
            <Link to="/alicia-scarborough">Youth</Link>
        </nav>
    </header>
  )
}

export default ALSHeader