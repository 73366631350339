import {useEffect,useState,useCallback} from 'react';

import useData from '../../hooks/useData';

const ASMain = () => {

  const { dataAS } = useData();

  const [updated, setIsUpdated] = useState(false);
  const [links,setLinks] = useState();
  const [featImg,setFeatImg] = useState();
  const [featImgAlt,setFeatImgAlt] = useState();
  const [postTitle,setPostTitle] = useState();
  const [postDesc,setPostDesc] = useState();

  const imgBaseURL = (process.env.NODE_ENV !== 'production') ? 'https://www.aliciascarborough.com/' : '';

  const unParse = (h) => {
    if(h) {
      const decoded = h.replaceAll('&lt;','<').replaceAll('&gt;','>').replaceAll('&quot;','"').replaceAll('\\/','/');
      return decoded;
    }
    return;
  }

  const updateLinks = (j) => {
    if(j?.links) {
      setLinks(j?.links?.map((link) => {
        return (
          <a href={link.url} key={link.name} className="btn white" rel="noreferrer" target="_blank">Buy on {link.name}</a>
        );
      }));
    }
  }

  const updateFeaturedImage = (j) => {
    setFeatImg(j?.featured_img);
    setFeatImgAlt(j?.title);
  }

  const updateFeaturedPost = useCallback((j) => {
    setPostTitle(j?.title);
    setPostDesc(unParse(j?.content));
  },[]);

  useEffect(()=>{
    if(dataAS && !updated) {
      updateLinks(dataAS?.latest[0]);
      updateFeaturedImage(dataAS?.latest[0]);
      updateFeaturedPost(dataAS?.latest[0]);
      setIsUpdated(true);
    }
  },[dataAS, updateFeaturedPost, updated]);


  return (
    <section className="content col-sm-12 main">
        <figure className="col-sm-3 col-xs-4">
          <img src={imgBaseURL+featImg} alt={featImgAlt} />
        </figure>
        <article className="col-sm-6 col-xs-10">
        <header>{postTitle}</header>
        <div dangerouslySetInnerHTML={{__html:postDesc}} />
        {(links) ? <footer>{links}</footer> : null }
        </article>
    </section>
  )
}

export default ASMain