import { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from "react-router-dom";

import useData from '../../hooks/useData';

const imgBaseURL = (process.env.NODE_ENV !== 'production') ? 'https://www.aliciascarborough.com/' : '';

const SSName = () => {

  const { dataALS } = useData();

  const params = useParams();

  const [seriesLoaded,setSeriesLoaded] = useState(false);
  const [ssSeriesData,setSSSeriesData] = useState();
  const [ssData,setSSData] = useState();
  const [ss,setSS] = useState();
  const [buyLinks,setBuyLinks] = useState();

  const [imgUrl,setImgUrl] = useState();
  const [ssTitle,setSSTitle] = useState();
  const [ssDesc,setSSDesc] = useState();

  const updateSS = useCallback(() => {    
    if(ss) {
      setImgUrl(imgBaseURL+ss.picture);
      setSSTitle(ss.bookTitle);
      setSSDesc(ss.bookDesc);
    }
  },[ss]);

  const updateBuyLinks = useCallback(() => {
    if(ss?.links){
      setBuyLinks(ss?.links?.map((lnk) => {
        return (
            <a href={lnk.url} target="_blank" rel="noreferrer" key={lnk.url+lnk.name} className="flex col-1 center trajan-regular">
              {lnk.name}
            </a>
        );
      }));
    }
  },[ss.links]);

  const updateSSData = useCallback(() => {
    if(ssSeriesData) {
      if(ssData) {
        updateSS();
        if(ss) {
          updateBuyLinks();
        }
      }
    }
  },[ss,ssData,ssSeriesData,updateSS,updateBuyLinks]);

  useEffect(()=>{
    if(dataALS) {
      if(!seriesLoaded) {
        setSSSeriesData(dataALS?.series?.filter((s => s.series_name === params.SeriesName)));
        if(ssSeriesData) {
          setSSData(ssSeriesData[0]?.shortStories?.filter((bk=>bk.bookTitle === params.BookName)));
          if(ssData){
            setSS(ssData[0]);
            if(ss){
              updateSSData();
              setSeriesLoaded(true);
            }
          }
        }
      }
    }
  },[dataALS, params.BookName, params.SeriesName, seriesLoaded, ss, ssData, ssSeriesData, updateSSData]);
  
  return (
    <section className="content col-sm-12 col-xs-12">
      <div className="col-sm-8 col-xs-10">
        <div className="breadcrumbs col-sm-12 col-xs-12">
            <Link to={"/al-scarborough/series/"+ss.series_name} className="trajan-regular"><span>Back to Series</span></Link>
        </div>
        <header className="center mobile">
          {ssTitle}
        </header>
        <div className="col-sm-12 col-xs-12 book">
          <div className="wrapper flex spacebetween">
            <div className="col-sm-4">
              <picture className="bookCover">
                <img src={imgUrl} alt="" />
              </picture>
            </div>
            <div className="col-sm-7">
              <h5 className="desktop">
                {ssTitle}
              </h5>
              <p dangerouslySetInnerHTML={{__html:ssDesc}}></p>
              {(buyLinks) ? <nav className="buyLinks spacebetween">{buyLinks}</nav> : null }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SSName