import React,{ createContext, useEffect, useState } from 'react';
//import * as alsData from '../data/alscarborough.json';
//import * as asData from '../data/aliciascarborough.json';

const ASJSONLOC = (process.env.NODE_ENV !== 'production') ? 'https://www.aliciascarborough.com/data/aliciascarborough.json' : '/data/aliciascarborough.json';
const ALSJSONLOC = (process.env.NODE_ENV !== 'production') ? 'https://www.aliciascarborough.com/data/alscarborough.json' : '/data/alscarborough.json';

const DataContext = createContext({});

export const DataProvider = ({children}) => {

    const getLocalASJson = JSON.parse(localStorage.getItem('localASJson') || '{}');
    const getLocalALSJson = JSON.parse(localStorage.getItem('localALSJson') || '{}');

    const [dataAS, setDataAS] = useState(getLocalASJson);
    const [dataALS, setDataALS] = useState(getLocalALSJson);

    const getASData = () => {
        (async () => {
        const response = await fetch(ASJSONLOC);
        const parsed = await response.json();
        //console.log('parsed',parsed);
        //setDataAS(parsed);
        localStorage.setItem('localASJson', JSON.stringify(parsed))
        setDataAS(parsed);
        })();
    }

    const getALSData = () => {
        (async () => {
        const response = await fetch(ALSJSONLOC);
        const parsed = await response.json();
        //console.log('parsed',parsed);
        //setDataALS(parsed);
        localStorage.setItem('localALSJson', JSON.stringify(parsed))
        setDataALS(parsed);
        })();
    }

    //const [dataALS] = useState(alsData);
    //const [dataAS] = useState(asData);

    // eslint-disable-next-line
    useEffect(() => { getASData();getALSData(); },[]);

    return (
        <DataContext.Provider value={{dataALS,dataAS}}>
            {children}
        </DataContext.Provider>
    )
}

export default DataContext;