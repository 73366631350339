import React from 'react'
import logo from '../imgs/alicia-scarborough.png';
import { NavLink,Link } from 'react-router-dom';
import ASSocialLinks from './ASSocialLinks';

const ASHeader = () => {
  return (
    <header className="header">
        <picture className="col-sm-2 col-xs-1">
            <Link to="/alicia-scarborough" className="img"><img src={logo} className="logo" alt="Alicia Scarborough - Spellbinding Storyteller" /></Link>
        </picture>
        <nav className="main col-sm-4 col-xs-6">
            <NavLink to="books" className={({ isActive }) => "nav-link" + (isActive ? " current" : "")}>Books</NavLink>
            <NavLink to="series" className={({ isActive }) => "nav-link" + (isActive ? " current" : "")}>Series</NavLink>
            <NavLink to="about" className={({ isActive }) => "nav-link" + (isActive ? " current" : "")}>About</NavLink>
            <NavLink to="newsletter" className={({ isActive }) => "nav-link" + (isActive ? " current" : "")}>Newsletter</NavLink>
        </nav>
        <nav className="social col-sm-2 desktop">
           <ASSocialLinks />
        </nav>
        <nav className="siteSwitch">
            <Link to="/al-scarborough">Adults</Link>
            <Link to="/alicia-scarborough" className="current">Youth</Link>
        </nav>
    </header>
  )
}

export default ASHeader