import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

import useData from '../../hooks/useData';

import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import "swiper/css/bundle";

const imgBaseURL = (process.env.NODE_ENV !== 'production') ? 'https://www.aliciascarborough.com' : '';

const ALSBooks = () => {

  const { dataALS } = useData();
  const [ books, setBooks ] = useState();
  const [swiperInit, setSwiperInit] = useState(false);
  const [windowWidth, setWindowWidth] = useState();
  const [swiperEl,setSwiperEl] = useState();

  const updateBooks = useCallback(() => {
    setBooks(dataALS?.series?.map((series) => {
      return(
        series?.books?.map((book) => {
          return (
            <Link to={"/al-scarborough/book/"+book.series_name+"/"+book.bookTitle} key={book.series_name+book.bookNo} className="flex col center trajan-regular swiper-slide">
              <img src={imgBaseURL+book.picture} alt={book.series_name + " | " +  book.bookTitle + " | Book " + book.bookNo} />
              <span style={{fontSize: '1.5rem'}}>{book.bookTitle}</span>
              <span style={{display: 'inline-block', fontSize: '.85rem', lineHeight: '1.1rem', marginTop: '.05rem'}}>{book.series_name}</span>
              <span style={{fontSize: '.9rem'}}>Book {book.bookNo}</span>
              <span style={{color: '#ffe974', fontSize: '1rem', lineHeight: '1rem', marginTop: '.5rem'}}>Learn More</span>
            </Link>
          );
        })
      );
    }));
  },[dataALS.series]);

  useEffect(()=>{
    if(dataALS) {
      updateBooks();
    }
  },[dataALS,updateBooks]);

  useEffect(()=>{
    if(swiperInit && windowWidth > 991) {
      if(swiperEl !== undefined) {
        console.log('destroy');
        swiperEl.destroy(true, true);
        setSwiperEl(undefined);
        setSwiperInit(false);
      }
    } else if (!swiperInit && windowWidth < 992) {
      if(swiperEl === undefined) {
        console.log('create');
        setSwiperEl(new Swiper('.swiper', {modules: [Pagination], centeredSlides: true, speed: 400, spaceBetween: 0, slidesPerView: 1.5, pagination: {el: '.swiper-pagination', clickable: true, type: 'bullets', dynamicBullets: true}}));
      } else {
        console.log('init');
        swiperEl.init();
      }
      setSwiperInit(true);
    }
  },[windowWidth,swiperEl,swiperInit]);

  const updateSize = (e) => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(()=>{    
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize',updateSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  return (
    <section className="content col-sm-12 col-xs-12">
      <div className="col-sm-8 col-xs-10">
        <header className="center">Books</header>
        <div className="col-sm-12 col-xs-12 books">
          <div className="col-sm-12 col-xs-12 swiper">
            <div className="wrapper grid swiper-wrapper">
              {books}
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ALSBooks