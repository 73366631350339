import { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from "react-router-dom";

import useData from '../../hooks/useData';

import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';
import "swiper/css/bundle";


const imgBaseURL = (process.env.NODE_ENV !== 'production') ? 'https://www.aliciascarborough.com' : '';

const SeriesName = () => {

  const { dataALS } = useData();
  const params = useParams();

  const [updated,setIsUpdated] = useState();
  const [seriesData,setSeriesData] = useState();
  const [seriesTitle, setSeriesTitle] = useState();
  const [seriesDesc, setSeriesDesc] = useState();
  const [bkSeries, setBkSeries] = useState();
  const [books, setBooks] = useState();
  const [shortStories, setShortStories] = useState();
  const [characters, setCharacters] = useState();
  const [swiperInit, setSwiperInit] = useState(false);
  const [windowWidth, setWindowWidth] = useState();
  const [swiperEl1,setSwiperEl1] = useState();
  const [swiperEl2,setSwiperEl2] = useState();
  const [swiperEl3,setSwiperEl3] = useState();

  const getCharacters = useCallback(() => {
    if(bkSeries?.characters) {
      setCharacters(bkSeries?.characters?.map((character) => {
        let alt = (character.name) ? character.name + " | " + bkSeries?.series_name : "";
        return (
          <Link to={"/al-scarborough/character/"+bkSeries?.series_name+"/"+character.name} key={bkSeries.series_abbr+character.name} className="flex col center trajan-regular swiper-slide">
            <span className="circle"><img src={imgBaseURL+character.picture} alt={alt} /></span><br />
            {character.name}
            <span style={{color: '#ffe974', fontSize: '1rem', lineHeight: '1rem', marginTop: '.5rem'}}>Learn More</span>
          </Link>
        );
      }));
    }
  },[bkSeries]);

  const getBooks = useCallback(() => {
    if(bkSeries?.books) {
      setBooks(bkSeries?.books?.map((book) => {
        return (
          <Link to={"/al-scarborough/book/"+bkSeries?.series_name+"/"+book.bookTitle} key={bkSeries.series_abbr+'_'+bkSeries.id+'_'+book.bookNo} className="flex col center trajan-regular swiper-slide">
            <img src={imgBaseURL+book.picture} alt={bkSeries?.series_name + " | " +  book.bookTitle + " | Book " + book.bookNo} />
            {book.bookTitle}
            <span style={{fontSize: '1rem'}}>Book {book.bookNo}</span>
            <span style={{display: 'inline-block', fontSize: '.85rem', lineHeight: '1.1rem'}}>{bkSeries?.series_name}</span>
            <span style={{color: '#ffe974', fontSize: '1rem', lineHeight: '1rem', marginTop: '.5rem'}}>Learn More</span>
          </Link>
        );
      }));
    }
  },[bkSeries]);

  const getShortStories = useCallback(() => {
    if(bkSeries?.shortStories) {
      setShortStories(bkSeries.shortStories.map((ss) => {
        return (
          <Link to={"/alicia-scarborough/short-story/"+bkSeries?.series_name+"/"+ss.bookTitle} key={bkSeries.series_abbr+'_'+bkSeries.id+'_'+ss.bookTitle} className="flex col center trajan-regular swiper-slide">
            <img src={imgBaseURL+ss.picture} alt={bkSeries?.series_name + " | " +  ss.bookTitle} />
            {ss.bookTitle}
            <span style={{color: '#ffe974', fontSize: '1rem', lineHeight: '1rem', marginTop: '.5rem'}}>Learn More</span>
          </Link>
        );
      }));
    }
  },[bkSeries]);

  const updateBookSeries = useCallback(() => {
    setBkSeries(seriesData[0]);
    if(bkSeries) {
      setSeriesTitle(bkSeries?.series_name);
      setSeriesDesc(bkSeries?.series_desc);
      getBooks();
      getShortStories();
      getCharacters();
      setIsUpdated(true);
    }
  }, [seriesData,bkSeries,getBooks,getShortStories,getCharacters]);

  useEffect(()=>{
    if(dataALS && !updated) {
      setWindowWidth(window.innerWidth);
      setSeriesData(dataALS?.series?.filter(((s) => { return s?.series_name === params.SeriesName })));
      if(seriesData) {
        updateBookSeries();
      }
    }
  },[dataALS, seriesData, updated, updateBookSeries, params.SeriesName]);

  useEffect(()=>{
    console.log(swiperInit,windowWidth);
    if(!windowWidth) {
      setWindowWidth(window.innerWidth);
    }
    if(swiperInit && windowWidth > 991) {
      setSwiperInit(false);
      if(swiperEl1 !== undefined) {
        console.log('destroy');
        swiperEl1.destroy(true, true);
        setSwiperEl1(undefined);
      }
      if(swiperEl2 !== undefined) {
        console.log('destroy');
        swiperEl2.destroy(true, true);
        setSwiperEl2(undefined);
      }
      if(swiperEl3 !== undefined) {
        console.log('destroy');
        swiperEl3.destroy(true, true);
        setSwiperEl3(undefined);
      }
    } else if (!swiperInit && windowWidth < 992) {
      setSwiperInit(true);
      if(books && books.length > 0) {
        if(swiperEl1 === undefined) {
          console.log('create');
          setSwiperEl1(new Swiper('.swiper.one', {modules: [Pagination], centeredSlides: true, speed: 400, spaceBetween: 0, slidesPerView: 1.5, pagination: {el: '.swiper-pagination.one', clickable: true, type: 'bullets', dynamicBullets: true}}));
        } else {
          console.log('init');
          swiperEl1.init();
        }
      }
      if(shortStories && shortStories.length > 0) {
        if(swiperEl2 === undefined) {
          console.log('create');
          setSwiperEl2(new Swiper('.swiper.two', {modules: [Pagination], centeredSlides: true, speed: 400, spaceBetween: 0, slidesPerView: 1.5, pagination: {el: '.swiper-pagination.two', clickable: true, type: 'bullets', dynamicBullets: true}}));
        } else {
          console.log('init');
          swiperEl2.init();
        }
      }
      if(characters && characters.length > 0) {
        if(swiperEl3 === undefined) {
          console.log('create');
          setSwiperEl3(new Swiper('.swiper.three', {modules: [Pagination], centeredSlides: true, speed: 400, spaceBetween: 0, slidesPerView: 1.5, pagination: {el: '.swiper-pagination.three', clickable: true, type: 'bullets', dynamicBullets: true}}));
        } else {
            console.log('init');
            swiperEl3.init();
        }
      } 
    }
  },[windowWidth,swiperEl1,swiperEl2,swiperEl3,swiperInit,books,shortStories,characters]);

  const updateSize = (e) => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(()=>{    
    setWindowWidth(window.innerWidth);
    if(windowWidth < 992) {
      setSwiperInit(false);
    } else {
      setSwiperInit(true);
    }
    console.log(windowWidth,swiperInit);
    window.addEventListener('resize',updateSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <section className="content col-sm-12 col-xs-12">
      <article className="col-sm-12 bookSeries">
        <div className="breadcrumbs col-sm-8 col-xs-10">
            <Link to={"/al-scarborough/series/"} className="trajan-regular"><span>Back to List of Series</span></Link>
        </div>
        <section className="col-sm-8 col-xs-10 dtls">
          <header className="center">{seriesTitle}</header>
          <div className="col-sm-12 col-xs-12">
            <div className="col-sm-12">
              <p dangerouslySetInnerHTML={{__html:seriesDesc}}></p>
            </div>
          </div>
        </section>
        {(books && books.length > 0) ?
        <section className="col-sm-8 col-xs-10 bks">
          <header className="center">Books</header>
          <div className="col-sm-12 col-xs-12 books">
            <div className="col-sm-12 col-xs-12 swiper one">
              <div className="wrapper grid-4 swiper-wrapper">
                {books}
              </div>
              <div className="swiper-pagination one"></div>
            </div>
          </div>
        </section>
        : null }
        {(shortStories && shortStories.length > 0) ?
        <section className="col-sm-8 col-xs-10 bks">
          <header className="center">Short Stories</header>
          <div className="col-sm-12 col-xs-12 books">
            <div className="col-sm-12 col-xs-12 swiper two">
              <div className="wrapper grid-4 swiper-wrapper">
                {shortStories}
              </div>
              <div className="swiper-pagination two"></div>
            </div>
          </div>
        </section> : null }
        {(characters && characters.length > 0) ?
        <section className="col-sm-8 col-xs-10 chars">
          <header className="center">Characters</header>
          <div className="col-sm-12 col-xs-12">
            <div className="col-sm-12 col-xs-12 swiper three">
              <div className="wrapper grid-4 swiper-wrapper">
                {characters}
              </div>
              <div className="swiper-pagination three"></div>
            </div>
          </div>
        </section>
        : null }
        <div className="breadcrumbs col-sm-8 col-xs-10" style={{paddingTop:'3%'}}>
            <Link to={"/al-scarborough/series/"} className="trajan-regular"><span>Back to List of Series</span></Link>
        </div>
      </article>
    </section>
  )
}

export default SeriesName