import React from 'react'
import ALSFooter from '../components/ALSFooter';
import ALSHeader from '../components/ALSHeader';
import { Outlet } from 'react-router-dom';

const ALScarborough = () => {
  return (
    <main className="al-scarborough trajan-regular">
      <ALSHeader />
      <Outlet />
      <ALSFooter />
    </main>
  )
}

export default ALScarborough