import React from 'react'

import authorphoto from '../../imgs/aliciascarborough_headshot_v3_sm.jpg';

const ASAbout = () => {
  return (
    <section className="content col-sm-12">
      <div className="col-sm-8">
        <header className="center">About</header>
        <div className="col-sm-12 flex about">
          <div className="col-sm-8">
            <h4>I am an author and writing is my daily coffee.</h4>
            <p>Alicia Scarborough is a writer of urban fantasy and paranormal thrillers, and a self-proclaimed cookie addict.</p>
            <p>When she's not weaving intricate tales of the supernatural, you can find her indulging in her other passions, which include devouring stacks of books, gardening in her backyard, and snuggling with her two adorable pups, Gatlin & Wyatt.</p>
            <p>As a bird enthusiast, she also dotes on her sweet green-cheek conure, Loki, who are always happy to lend an ear to her latest plot ideas. When inspiration strikes, she can often be found doodling her characters in the margins of her notebook.</p>
            <p>And if you ever need to bribe her for insider knowledge, just bring her a pack of Oreos - the darker the better. Just don't get on her bad side or you may end up as fodder for her next book.</p>
          </div>
          <div className="col-sm-1"></div>
          <div className="col-sm-3">
            <picture className="circleAuthorPhoto">
              <img src={authorphoto} alt="" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ASAbout