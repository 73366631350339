import React from 'react'
import ASFooter from '../components/ASFooter';
import ASHeader from '../components/ASHeader';
import { Outlet } from 'react-router-dom';

const AliciaScarborough = () => {
  return (
    <main className="alicia-scarborough">
      <ASHeader />
      <Outlet />
      <ASFooter />
    </main>
  )
}

export default AliciaScarborough