
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LP from './pages/LP';
import ALScarborough from './pages/ALScarborough';
import AliciaScarborough from './pages/AliciaScarborough';
//Import AL Scarborough pages
import ALSAbout from './pages/alscarborough/About';
import ALSBooks  from './pages/alscarborough/Books';
import ALSBookName  from './pages/alscarborough/BookName';
import ALSCharacterName  from './pages/alscarborough/CharacterName';
import ALSShortStories  from './pages/alscarborough/SS';
import ALSShortStoryName  from './pages/alscarborough/SSName';
import ALSSeries from './pages/alscarborough/Series';
import ALSSeriesName from './pages/alscarborough/SeriesName';
import ALSNewsletter from './pages/alscarborough/Newsletter';
import ALSMain from './pages/alscarborough/Main';
//Import Alicia Scarborough pages
import ASAbout from './pages/aliciascarborough/About';
import ASBooks  from './pages/aliciascarborough/Books';
import ASBookName  from './pages/aliciascarborough/BookName';
import ASShortStories  from './pages/aliciascarborough/SS';
import ASShortStoryName  from './pages/aliciascarborough/SSName';
import ASSeries from './pages/aliciascarborough/Series';
import ASSeriesName from './pages/aliciascarborough/SeriesName';
import ASNewsletter from './pages/aliciascarborough/Newsletter';
import ASMain from './pages/aliciascarborough/Main';

import { DataProvider } from './context/DataContext';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
      <DataProvider>
        <Routes>
          <Route path="/al-scarborough" element={<ALScarborough />}>
            <Route index element={<ALSMain />} />
            <Route path="book/:SeriesName/:BookName" element={<ALSBookName />} />
            <Route path="short-story/:SeriesName/:BookName" element={<ALSShortStoryName />} />
            <Route path="series/:SeriesName" element={<ALSSeriesName />} />
            <Route path="character/:SeriesName/:Name" element={<ALSCharacterName />} />
            <Route path="books" element={<ALSBooks />} />
            <Route path="short-stories" element={<ALSShortStories />} />
            <Route path="series" element={<ALSSeries />} />
            <Route path="about" element={<ALSAbout />} />
            <Route path="newsletter" element={<ALSNewsletter />} />
          </Route>
          <Route path="/alicia-scarborough" element={<AliciaScarborough />}>
            <Route index element={<ASMain />} />
            <Route path="book/:SeriesName/:BookName" element={<ASBookName />} />
            <Route path="short-story/:SeriesName/:BookName" element={<ASShortStoryName />} />
            <Route path="series/:SeriesName" element={<ASSeriesName />} />
            <Route path="books" element={<ASBooks />} />
            <Route path="short-stories" element={<ASShortStories />} />
            <Route path="series" element={<ASSeries />} />
            <Route path="about" element={<ASAbout />} />
            <Route path="newsletter" element={<ASNewsletter />} />
          </Route>
          <Route index path="/" element={<LP />} />
        </Routes>
      </DataProvider>
    </BrowserRouter>
    </div>
  );
}

export default App;
