import React from 'react'
import btmLogo from '../imgs/initials-as-bw.png';
import { NavLink, Link } from 'react-router-dom';
import ASSocialLinks from './ASSocialLinks';

const ASFooter = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
        <div className="wrapper col-sm-12">
            <div className="col-sm-2 col-xs-12 ql">
                <h5>Quick Links</h5>
                <ul>
                    <li><NavLink to="newsletter" className={({ isActive }) => "nav-link" + (isActive ? " current" : "")}>Newsletter</NavLink></li>
                </ul>
            </div>
            <div className="col-sm-4 col-xs-12 center lg">
                <picture>
                    <Link to="/alicia-scarborough" className="img"><img src={btmLogo} className="logo" alt="Alicia Scarborough - Spellbinding Storyteller" /></Link>
                </picture>
            </div>
            <div className="col-sm-2 col-xs-12 center sc">
                <h5>Social</h5>
                <nav className="social">
                    <ASSocialLinks />
                </nav>
            </div>
        </div>
        <div className="legal">&copy; {year} All Rights Reserved.</div>
    </footer>
  )
}

export default ASFooter