import React from 'react'
import nlphoto from '../../imgs/nl-backdrop.png';

const ASNewsletter = () => {
  return (
    <section className="content col-sm-12">
      <div className="col-sm-8">
        <header className="center">Join My Newsletter</header>
        <div className="col-sm-12 flex newsletter">
          <div className="col-sm-9">
            <h4 className="w90 trajan-semibold">
              The&nbsp;A.L.&nbsp;Scarborough Chronicles: Your Weekly Portal to Urban Fantasy & Paranormal Thrillers
            </h4>
            <p className="fs1_2 w90">Are you a fan of urban fantasy and paranormal novels?</p>
            <p className="fs1_2 w90">Do you want exclusive access to updates on my latest works in progress, new characters, character profile art, behind-the-scenes peeks, and new releases?</p>
            <p className="fs1_2 w90">Then sign up for my weekly Urban Fantasy Paranormal newsletter! As a subscriber, you'll get character takeovers, exclusive insights, and invites to events.</p>
            <p className="fs1_2 w90">Join our community of like-minded readers who love exploring supernatural worlds and get all the inside scoop on my latest works.</p>
            <p className="fs1_2 w90">Don't miss out on the chance to dive deeper into my novels and be the first to hear about my upcoming releases.</p>
            
            <a href="https://landing.mailerlite.com/webforms/landing/u0a4t9" rel="noreferrer" target="_blank">Sign Up Now!</a>
          </div>
          <div className="col-sm-3 col-xs-8 flex end">
            <picture className="">
              <img src={nlphoto} alt="" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ASNewsletter